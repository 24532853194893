<template>
  <div>
    <iframe     src="http://localhost:8069"
               width="100%" height="800"
               marginwidth="0" marginheight="0"
               frameborder="no" scrolling="no"
               style="border-width:0px; ">
       </iframe>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos

export default {
  components: {},
  setup() {
    return {}
  },
}
</script>
